<template>
    <div>
      <!-- Error message (shown when there's an error) -->
      <transition name="fade">
        <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">{{ errorMessage }}</span>
        </div>
      </transition>
  
      <!-- Form Fields -->
      <div class="flex flex-wrap mb-6">
        <div class="w-full">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-email">
            Email
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-email" type="email" v-model="formData.field_email">
        </div>
      </div>
  
      <div class="flex flex-wrap mb-6">
        <div class="w-full md:w-1/2 md:pr-3 mb-6 md:mb-0">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-password">
            Password
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-password" type="password" v-model="formData.field_password">
        </div>
        <div class="w-full md:w-1/2 md:pl-3">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-passwordConfirm">
            Confirm Password
          </label>
          <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-passwordConfirm" type="password" v-model="formData.field_passwordConfirm">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import companyService from '@/services/marketplace/company.service';
  
  export default {
    props: ['formData'],
    inject: ['toggleLoading'],
    data() {
      return {
        error: false,
        errorMessage: "",
        fadeTimeout: null,
      };
    },
    methods: {
      showError(message) {
        this.error = true;
        this.errorMessage = message;
  
        // Clear previous timeout if it exists
        if (this.fadeTimeout) {
          clearTimeout(this.fadeTimeout);
        }
  
        // Hide the error after 5 seconds
        this.fadeTimeout = setTimeout(() => {
          this.error = false;
        }, 5000); // Error message stays visible for 5 seconds
      },
      async checkEmail() {
          try {
              const checkEmailResponse = await companyService.checkEmail(this.formData.field_email);
  
              if (checkEmailResponse && !checkEmailResponse.data.error) {  // Assuming 'exists' is in the response
                  return true;
              } else {
                  return false;
              }
          } catch (error) {
              return false;
          }
      },
      async validate() {
        this.toggleLoading(true);
        // Reset error state
        this.error = false;
        this.errorMessage = '';
  
        let isValid = true;
  
        // Validate password and confirmation match
        if (this.formData.field_password !== this.formData.field_passwordConfirm) {
          // this.showError('Passwords do not match.');
          this.errorMessage = 'Passwords do not match.';
          isValid = false;
        }
  
        // Validate password length (e.g., minimum 8 characters)
        if (!this.formData.field_password || this.formData.field_password.length < 8) {
          // this.showError('Password must be at least 8 characters long.');
          this.errorMessage = 'Password must be at least 8 characters long.';
          isValid = false;
        }
  
        // Validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.formData.field_email || !emailPattern.test(this.formData.field_email)) {
          // this.showError('Please enter a valid email address.');
          this.errorMessage = 'Please enter a valid email address.';
          isValid = false;
        }
    
        let emailAvailable = await this.checkEmail()
        if (!emailAvailable) {
          this.errorMessage = 'This email address is already registered.';
          isValid = false;
        } 
  
        // If valid, emit the formData
        if (isValid) {
          this.$emit('submit-step1', this.formData); // Emit form data to parent
        } else {
          this.$root.notify(this.errorMessage, 'error');
        }
  
        this.toggleLoading(false);
  
        return isValid;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Fade transition for both fade-in and fade-out */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  