<template>
    <transition name="fade">
      <div v-if="visible" v-bind:class="{'bg-green-50 text-green-800': this.type == 'success',  'bg-red-50 text-red-800': this.type == 'error',  'bg-yellow-50 text-yellow-800': this.type == 'warning'}" class="fixed top-5 right-5 z-50 p-4 mb-4 text-sm rounded-lg shadow-md" role="alert">
        <span v-if="this.type == 'error'" class="font-bold">Error! </span>
        <span v-if="this.type == 'warning'" class="font-bold">Warning! </span>
        {{ message }}
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
        type: 'success',
        message: '',
        timeout: null,
      };
    },
    methods: {
      showNotification(message, type = 'success', duration = 3000) {
        this.message = message;
        this.visible = true;
        this.type = type;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.visible = false;
        }, duration);
      }
    }
  };
  </script>
  
  <style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  </style>