<template>
  <header class="bg-white drop-shadow-md">
    <nav class="flex justify-between items-center text-white py-6 px-3 xl:container xl:mx-auto">
      <div class="flex">
        <a href="/admin/profiles" class="logo">
          <img class="w-1/2 ml-2" src="@/assets/logo.png" alt="Screened logo">
        </a>
      </div>

      <div class="flex md:hidden">
        <button class="flex items-center mr-2 px-3 py-2 border rounded text-gray-400 border-gray-400 hover:text-gray-300 hover:border-gray-300">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>

      <div class="hidden md:flex md:items-center">
        <router-link to="/marketplace" class="flex items-center ml-3 text-gray-500 hover:text-sky-500 hidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg>
          <span class="ml-1 mr-1 ">Dashboard</span>
        </router-link>

        <!-- <router-link class="flex items-center ml-3 text-gray-500 hover:text-sky-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>

          <span class="ml-1">Account</span>
        </router-link> -->

        <router-link v-if="isLoggedIn" to="/company/logout" class="flex items-center ml-3 text-gray-500 text-gray-500 hover:text-sky-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>
          <span class="ml-1">Logout</span>
        </router-link>
      </div>
    </nav>
  </header>

</template>

<script>
export default {
  name: 'CompanyHeader',
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
      isLoggedIn() {
          return this.$store.state.isCompanyLoggedIn;
      }
  }
}
</script>