<template>
    <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
      <span class="block sm:inline">{{ errorMessage }}</span>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-name">
                Company name
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-name" type="tel" v-model="formData.company_name">
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-website">
                Website
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-website" type="tel" v-model="formData.company_website">
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-office">
                Head Office Address (Australia)
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-office" type="tel" v-model="formData.company_office">
        </div>
    </div>

    <!-- Industry Dropdown -->
    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label for="industry" class="block text-sm font-medium text-gray-700">Select Industry</label>
            <select id="industry" v-model="this.formData.selectedIndustry" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option disabled selected value="">-- Select Industry --</option>
                <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
            </select>
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-summary">
                Company summary (200 words)
            </label>
            <textarea class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight" id="grid-summary" cols="30" rows="3" v-model="this.formData.company_summary"></textarea>
        </div>
    </div>
</template>
  
<script>
import genericService from '../../../../services/marketplace/generic.service';

export default {
    props: ['formData'],
    inject: ['toggleLoading'],
    data() {
        return {
            error: false,
            errorMessage: "",
            profilePicturePreview: null,  // Holds the image URL
            showModal: false,    // Controls whether the modal is shown
            industries: [],         // List of industries fetched from API
            selectedIndustry: '',   // Selected industry ID
        }
    },
    methods: {
        initiateDefaultValues() {
            this.formData.selectedIndustry = this.formData.selectedIndustry !== undefined ? this.formData.selectedIndustry : '';
        },
        async fetchIndustries() {
        try {
            const response = await genericService.getIndustries();
            this.industries = response.data;
        } catch (error) {
            console.error('Error fetching industries:', error);
        }
    },
      validate() {
        this.toggleLoading(true);
        // Reset error state
        this.error = false;
        this.errorMessage = '';
  
        let isValid = true;

        // Company address
        if (!this.formData.company_summary) {
            this.errorMessage = 'Please provide a summary of your company (200 words)';
            isValid = false;
        }

        if (!this.formData.selectedIndustry) {
            // this.showError('Please select an industry from the list.');
            this.errorMessage = 'Please select an industry from the list.'
            isValid = false;
        }

        // Company address
        if (!this.formData.company_office) {
            this.errorMessage = 'Please provide the address of your head office.';
            isValid = false;
        }

        // Company website
        if (!this.formData.company_website) {
            this.errorMessage = 'Please provide your company website.';
            isValid = false;
        }

        // Company name
        if (!this.formData.company_name) {
            this.errorMessage = 'Please provide your company name.';
            isValid = false;
        }
  
        // If valid, emit the formData
        if (isValid) {
            this.$emit('submit-step2', this.formData); // Emit form data to parent
        } else {
            this.$root.notify(this.errorMessage, 'error');
        }
  
        this.toggleLoading(false);
  
        return isValid;
      }
    },
    mounted() {
      this.initiateDefaultValues();
      this.fetchIndustries();
    }
  };
  </script>
  
  <style scoped>
  /* Fade transition for both fade-in and fade-out */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  /* You can adjust image styles as needed */
  img {
    max-width: 100%; /* Ensures the image doesn't overflow the container */
    height: auto;   /* Maintains aspect ratio */
  }
  </style>
  