<template>
    <div class="pt-7 flex justify-center">
        <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-11/12 md:w-1/2">
            <div class="-mt-6 -ml-3 md:-ml-8 -mr-3 md:-mr-8 rounded-t-xl bg-sky-500 pt-4 pl-6 md:pl-8">
                <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-50">Password reset</div>
            </div>

            <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">
                <span class="block sm:inline">{{ errorMessage }} <a class="text-blue-500 hover:text-blue-700 cursor-pointer" href="https://screened.com.au">Click here to return to the homepage.</a></span>
            </div>
            
            <div v-if="showForm">
                <p class="text-gray-600 my-6">
                    Please enter a new password for your account. Make sure it's strong and something you'll remember. Once you submit, your new password will be active immediately.
                </p>
                <!-- Form Fields -->
                <div class="flex flex-wrap my-6">
                    <div class="w-full">
                        <label class="block tracking-wide text-gray-800 text-sm mb-2" for="password">
                            Password
                        </label>
                        <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="password" type="password" v-model="password">
                    </div>
                </div>

                <div class="flex flex-wrap mb-6">
                    <div class="w-full">
                        <label class="block tracking-wide text-gray-800 text-sm mb-2" for="passwordVerify">
                        Password verify
                        </label>
                        <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="passwordVerify" type="password" v-model="passwordConfirm">
                    </div>
                </div>

                <div class="flex items-end justify-between">
                    <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitReset">
                        Reset
                    </button>
                </div>
            </div>

            <div v-if="showConfirmation" class="text-center my-6">
                <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-800">
                    Your password has been successfully changed!
                </div>
                <!-- Message -->
                <p class="text-gray-600 mb-6">
                    Your password has been updated, and you can now use it to access your account securely.
                </p>
      
                <!-- Instructions -->
                <p class="text-gray-600 mb-8">
                    Head over to the login page to sign in with your new password. If you run into any issues, feel free to reach out to our support team.
                </p>

                <!-- Login Button -->
                <router-link 
                    to="/contractor/login" 
                    class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-md inline-block transition duration-150 ease-in-out"
                >
                    Go to Login
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import passwordService from '@/services/password.service';

export default {
    name: 'ResetPassword',
    inject: ['toggleLoading'],
    data() {
        return {
            error: false,
            showForm: false,
            errorMessage: '',
            password: '',
            passwordConfirm: '',
            showConfirmation: false,
            redirectURL: ''
        }
    },
    methods: {
        async submitReset() {
            this.toggleLoading(true);

            if (this.password && this.passwordConfirm && this.password == this.passwordConfirm && this.password.length >= 8) {
                let resp = await passwordService.submitResetPassword(this.password, this.$route.params.token)

                if (resp && resp.data) {
                    if (resp.data.error) {
                        this.showForm = false
                        this.error = true
                        this.errorMessage = 'Something went wrong while processing your request. Please try requesting a new password reset link.'
                    } else {
                        this.error = false
                        this.showForm = false
                        this.showConfirmation = true;
                        this.redirectURL = resp.data.redirectURL
                    }
                } else {
                    this.showForm = false
                    this.error = true
                    this.errorMessage = 'Something went wrong while processing your request. Please try requesting a new password reset link.'
                }
                this.toggleLoading(false);
            } else {
                this.toggleLoading(false);
                this.error = true
                this.errorMessage = 'The passwords are not matching or the lenght is below the minimum requirment of 8 characters.'
            }
            
        }
    },
    async created() {
        this.toggleLoading(true);
        let token = this.$route.params.token

        if (token) {
            let tokenCheck = await passwordService.checkResetToken(token)

            this.toggleLoading(false);

            if (tokenCheck && tokenCheck.data) {
                if (!tokenCheck.data.error) {
                    this.error = false
                    this.showForm = true
                } else {
                    this.error = true
                    switch(tokenCheck.data.message) {
                    case 'TOKENNOTFOUND':
                        this.errorMessage = 'The password reset link is invalid. Please request a new link.'
                        break;
                    case 'TOKENNOTACTIVE':
                        this.errorMessage = 'This password reset link has already been used or is no longer valid because a new reset request was made. Please check your email for the latest link or request a new one.'
                        break;
                    case 'TOKENEXPIRED':
                        this.errorMessage = 'The password reset link has expired. Please request a new link to reset your password.'
                        break;
                    default:
                        this.errorMessage = 'Something went wrong while processing your request. Please try requesting a new password reset link.'
                    }
                }
            } else {
                this.errorMessage = 'Something went wrong while processing your request. Please try requesting a new password reset link.'
            }
        } else {
            this.error = true
            this.errorMessage = 'Your request is invalid'
            this.toggleLoading(false);
        }
    }
}
</script>