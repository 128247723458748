<template>
  <div class="space-y-4">
    <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
      <span class="block sm:inline">{{ this.errorMessage }}</span>
    </div>

    <!-- Industry Dropdown -->
    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label for="industry" class="block text-sm font-medium text-gray-700">Select Industry</label>
        <select id="industry" v-model="this.formData.selectedIndustry" @change="fetchRoles" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option disabled selected value="">-- Select Industry --</option>
          <option v-for="industry in industries" :key="industry.id" :value="industry.id">{{ industry.name }}</option>
        </select>
      </div>
    </div>

    <!-- Roles Dropdown (shown when an industry is selected) -->
    <div v-if="roles.length > 0" class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label for="role" class="block text-sm font-medium text-gray-700">Select Role</label>
        <select id="role" v-model="this.formData.selectedRole" @change="fetchQuestions" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option disabled value="">-- Select Role --</option>
          <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
        </select>
      </div>
    </div>

    <!-- Questions and Text Areas (shown when questions are fetched for the selected role) -->
    <div v-if="this.formData.questions && this.formData.questions.length > 0">
      <div v-for="(question, index) in this.formData.questions" :key="question.id" class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <!-- Display the question -->
          <label :for="'question-' + question.id" class="block text-sm font-medium text-gray-700">
            {{ question.question }}
          </label>

          <!-- Textarea for the user's answer to the question -->
          <textarea
              :id="'question-' + question.id"
              v-model="this.formData.answers[index]"
              rows="4"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Write your answer here..."
          ></textarea>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
        <!-- Availability -->
        <div class="w-full md:w-5/12 px-3 mb-6 md:mb-0">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-availability">
                Availability
            </label>
        
            <div class="relative">
                <select @change="availabilityChange($event)" class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight" id="grid-availability" v-model="this.formData.selectedAvailability">
                    <option disabled value="">Select an option</option>
                    <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
                </select>
            </div>

            <Popover v-if="calendarOpen" class="relative">
                <PopoverPanel class="absolute z-10">
                    <Datepicker class="my-3" v-model="date" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
                </PopoverPanel>
            </Popover>
        </div>

        <!-- Rate -->
        <div class="w-full md:w-3/12 px-3 mb-6 md:mb-0">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-rate">
                Rate
            </label>
            <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-rate" type="number" v-model="this.formData.field_rate">
        </div>

        <!-- Rate type -->
        <div class="w-full md:w-4/12 px-3">
            <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-rateType">
                Rate type
            </label>
            <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-rateType" v-model="formData.field_rateType">
                <option disabled value="">Select an option</option>
                <option value="hour">Hourly</option>
                <option value="day">Daily</option>
            </select>
        </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3 mb-6 md:mb-0">
        <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-contractStatus">
          Contract Status
        </label>
        <!-- <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-contractStatus" type="text" v-model="this.formData.field_contractStatus"> -->
        <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-contractStatus" v-model="formData.field_contractStatus">
          <option disabled value="">Select an option</option>
          <option value="PTY">PTY</option>
          <option value="PAYG">PAYG</option>
        </select>
      </div>

      <!-- <div class="w-full md:w-1/2 px-3">
          <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-workTravel">
              Will travel for work
          </label>
          <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-workTravel" v-model="formData.field_workTravel">
              <option disabled value="">Select an option</option>
              <option value="Y">Yes</option>
              <option value="N">No</option>
          </select>
      </div> -->
    </div>

    <div class="flex flex-wrap -mx-3 mb-6">
      <div class="w-full px-3">
        <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-roleDescription">
          Short description of preferred role/project
        </label>
        <textarea class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight" name="" id="grid-roleDescription" cols="30" rows="3" v-model="this.formData.field_roleDescription"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import genericService from '../../../../services/marketplace/generic.service';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    Datepicker
  },
  props: ['formData'],
  inject: ['toggleLoading'],
  data() {
    return {
      date: new Date(),
      availability: 0,
      calendarOpen: false,
      availabilityOptions: [
        // { "value": 0, "label": "Select availability" },
        { "value": 0.1, "label": "Now"},
        { "value": 0.2, "label": "1 week" },
        { "value": 0.5, "label": "2 weeks" },
        { "value": 1, "label": "1 month" },
        { "value": 999, "label": "Custom date" }
      ],
      industries: [],         // List of industries fetched from API
      selectedIndustry: '',   // Selected industry ID
      roles: [],              // List of roles based on selected industry
      selectedRole: '',       // Selected role ID
      questions: [],          // List of questions based on selected role
      answers: [],             // Array to hold the answers for each question
      error: false,
      errorMessage: "",
      fadeTimeout: null, // To store the timeout for error message fade out
    }
  },
  methods: {
    initiateDefaultValues() {
      if (this.formData.selectedAvailability !== undefined) {
        // If selected availability is higher than 1 it means it is a custom date so it needs to be pushed into the array
        if (this.formData.selectedAvailability > 1) {
          const dateObj = new Date(this.formData.selectedAvailability * 1000);

          // Add the new date to the dropdown
          this.availabilityOptions.push({
            "value": this.formData.selectedAvailability,
            "label": "From " + dateObj.toLocaleDateString()
          })
        }
      }

      this.formData.selectedAvailability = this.formData.selectedAvailability !== undefined ? this.formData.selectedAvailability : '';
      this.formData.field_contractStatus = this.formData.field_contractStatus !== undefined ? this.formData.field_contractStatus : '';
      this.formData.selectedIndustry = this.formData.selectedIndustry !== undefined ? this.formData.selectedIndustry : '';
      this.formData.field_rateType = this.formData.field_rateType !== undefined ? this.formData.field_rateType : '';
      this.formData.questions = this.formData.questions !== undefined ? this.formData.questions : [];
      this.formData.answers = this.formData.answers !== undefined ? this.formData.answers : [];
    },
    // Fetch industries when the component is mounted
    async fetchIndustries() {
      try {
        const response = await genericService.getIndustries();
        this.industries = response.data;
      } catch (error) {
        console.error('Error fetching industries:', error);
      }
    },

    // Fetch roles when an industry is selected
    async fetchRoles() {
      this.roles = [];
      this.formData.selectedRole = '';
      this.formData.questions = [];
      this.formData.answers = [];

      if (this.formData.selectedIndustry) {
        try {
          const response = await genericService.getRoles(this.formData.selectedIndustry);
          this.roles = response.data;
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    },

    // Fetch questions when a role is selected
    async fetchQuestions() {
      this.formData.questions = [];
      this.formData.answers = [];

      if (this.formData.selectedRole) {
        try {
          const response = await genericService.getQuestions(this.formData.selectedRole);
          this.formData.questions = response.data;

          // Initialize answers array with empty strings, one for each question
          this.formData.answers = this.formData.questions.map(() => '');
          if (!Array.isArray(this.formData.answers)) {
            this.formData.answers = [this.formData.answers];
          }
        } catch (error) {
          console.error('Error fetching questions:', error);
        }
      }
    },
    availabilityChange(event) {
      if (event.target.value == 999) {
        this.calendarOpen = true
      } else {
        this.calendarOpen = false
      }
    },
    updateCalendarDate(newDate) {
      let newSelectedDate = (newDate.getTime() / 1000)
      this.calendarOpen = false

      // If we already have 5 dates, remove the latest one
      if (this.availabilityOptions.length >= 6) {
        this.availabilityOptions.pop();
      }

      // Add the new date to the dropdown
      this.availabilityOptions.push({
        "value": newSelectedDate,
        "label": "From " + newDate.toLocaleDateString()
      })

      this.formData.selectedAvailability = newSelectedDate
    },
    showError(message) {
      this.error = true;
      this.errorMessage = message;

      // Clear previous timeout if it exists
      if (this.fadeTimeout) {
        clearTimeout(this.fadeTimeout);
      }

      // Hide the error after 5 seconds
      this.fadeTimeout = setTimeout(() => {
        this.error = false;
      }, 5000); // Error message stays visible for 5 seconds
    },
    validate() {
      this.toggleLoading(true);
      // Reset error state
      this.error = false;
      this.errorMessage = '';

      let isValid = true;

      // Validation for dynamic answers array
      this.formData.answers.forEach((answer, index) => {
        if (!answer || answer.trim() === '') {
          // this.showError(`All questions are mandatory.`);
          this.errorMessage = 'All questions are mandatory.';
          isValid = false;
        }
      });

      if (!this.formData.selectedRole) {
        // this.showError('Please select a role from the list.');
        this.errorMessage = 'Please select a role from the list.';
        isValid = false;
      }

      if (!this.formData.selectedIndustry) {
        // this.showError('Please select an industry from the list.');
        this.errorMessage = 'Please select an industry from the list.'
        isValid = false;
      }

      if (!this.formData.field_roleDescription) {
        // this.showError('Please provide a short description of your preferred role.');
        this.errorMessage = 'Please provide a short description of your preferred role.'
        isValid = false;
      }

      // if (!this.formData.field_workTravel) {
      //     this.showError('Let us know if you are willing to travel for work.');
      //     this.errorMessage = 'Let us know if you are willing to travel for work.';
      //     isValid = false;
      // }

      if (!this.formData.field_contractStatus) {
        // this.showError('The Contract Status field is mandatory.');
        this.errorMessage = 'The Contract Status field is mandatory.';
        isValid = false;
      }

      if (!this.formData.field_rateType) {
          this.errorMessage = 'Please select a rate type.'
          isValid = false;
      }

      if (!/^\d+(\.\d+)?$/.test(this.formData.field_rate) || parseFloat(this.formData.field_rate) <= 0) {
          this.errorMessage = 'The rate value has to be a positive number';
          isValid = false;
      }

      if (!this.formData.field_rate) {
        // this.showError('Select a valid rate (excl. super)');
        this.errorMessage = 'Select a valid rate (excl. super).'
        isValid = false;
      }

      if (!this.formData.selectedAvailability) {
        // this.showError('Please select your availability.');
        this.errorMessage = 'Please select your availability.';
        isValid = false;
      }

      // If valid, emit the formData
      if (isValid) {
        this.$emit('submit-step3', this.formData); // Emit form data to parent
      } else {
        this.$root.notify(this.errorMessage, 'error');
      }

      this.toggleLoading(false);

      return isValid;
    }
  },
  mounted() {
    this.initiateDefaultValues();
    // Fetch industries when the component is displayed
    this.fetchIndustries();
  }
};
</script>