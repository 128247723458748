<template>
  <div class="flex flex-col">
    <div v-if="!this.showCreate" @click="openPoolCreationForm" class="flex items-center justify-end p-3 select-none">
      <div class="flex items-center flex-col text-olive cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span class="ml-1 mr-1 font-bold">Create talent pool</span>
      </div>
    </div>
    
    <div v-if="this.showCreate" class="flex flex-col bg-white mb-5 rounded-xl p-4">
      <div class="flex items-center justify-between text-olive">
        <div class="text-black pt-5 pb-2 text-xl font-bold">Create talent pool</div>
        <div @click="closePoolCreationForm" class="cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></div>
      </div>

      <hr />

      <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-5" role="alert">
        <strong class="font-bold">Error! </strong>
        <span v-if="!this.errorMessage" class="block sm:inline">Something went wrong when attempting to create the new talent pool, please try again.</span>
        <span v-if="this.errorMessage" class="block sm:inline">{{ this.errorMessage }}</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>

      <div class="flex pt-5 space-x-5">
        <div class="flex-1 flex-col">
          <div class="font-bold py-1">Name</div>
          <div>
            <input v-model="this.poolCreateForm.name" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
          </div>
        </div>
        <div class="flex-1 flex-col">
          <div class="font-bold py-1">Location</div>
          <div>
            <input v-model="this.poolCreateForm.location" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-2">
        <div class="font-bold py-1">Description</div>
        <div>
          <textarea v-model="this.poolCreateForm.description" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" rows="3"></textarea>
        </div>
      </div>
      <div class="flex flex-col mt-2">
        <div class="font-bold py-1">Tags</div>
        <div class="my-3">
          <div v-for="item in this.poolCreateForm.tags" :key="item" class="ml-2 mr-2 select-none my-1 text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-sky-500 text-white rounded-full" >
            {{ item }}
            <span class="ml-3 text-sm cursor-pointer" @click="removeTag(item)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </span>
          </div>
          <div v-if="this.poolCreateForm.tags.length == 0">There are no tags added to this talent pool yet.</div>
        </div>
        <div class="flex flex-row">
          <input v-on:keyup.enter="addTag" v-model="this.tag" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" placeholder="Type a tag and press enter"/>
        </div>
      </div>
      <div class="flex mt-5 justify-end">
        <button class="bg-sky-500 px-5 py-2 rounded-md text-white font-bold hover:shadow-md" @click="createPool">Create talent pool</button>
      </div>
    </div>

    <div v-if="!this.hasFinishedLoading" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
      Please wait, loading your talent pools...
    </div>

    <div v-if="this.hasFinishedLoading && this.talentpools.length == 0" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
      You don't have any talent pool in your account yet.
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-5">
      <TalentPoolCard v-for="talentpool in talentpools" :talentpool="talentpool" :key="talentpool.talent_pool_hash" />
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

import TalentPoolCard from '@/components/TalentPoolCard.vue'

export default {
  name: 'TalentPools',
  components: {
    TalentPoolCard
  },
  data() {
    return {
      tag: '',
      talentpools: [],
      poolCreateForm: {
        name: "",
        location: "",
        description: "",
        tags: []
      },
      showCreate: false,
      error: null,
      errorMessage: null,
      hasFinishedLoading: false,
    };
  },

  methods: {
    removeTag(item) {
      let index = this.poolCreateForm.tags.indexOf(item)
      if (index !== -1) {
        this.poolCreateForm.tags.splice(index, 1);
      }
    },
    addTag() {
      if (!this.poolCreateForm.tags.includes(this.tag)) {
        this.poolCreateForm.tags.push(this.tag)
      }

      this.tag = ''
    },
    async getAllPools() {
      try {
        
        // let response = await fetch(apiEndpoint + "/talentpool.php?getAll");
        let response = await fetch(apiEndpoint + "/talentpool.php?getAll", {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.talentpools = await response.json();
        this.hasFinishedLoading = true
      } catch (error) {
        this.$router.push('Logout')
      }
    },
    createPool() {
      this.resetError()
      try {
        fetch(apiEndpoint + "/talentpool.php?createPool", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken')
          },
          body: JSON.stringify(this.poolCreateForm),
          })
          .then(response => response.json())
          .then(data => {
            
            // this.saveButtonText = 'Save changes'
            if (data.status == 'SUCCESS') {
              this.getAllPools();
              this.resetCreateForm();
              this.resetError()
              // Hide creation form
              this.showCreate = false;
             } else {
               if (data.status != 'ERROR') {
                 this.errorMessage = data.status
               }

               this.error = true;
             }
          })
        } catch (error) {
          console.log(error);
          this.error = true;
          // this.saveButtonText = 'Save changes'
      }
    },
    resetCreateForm() {
      this.poolCreateForm.name = "";
      this.poolCreateForm.location = "";
      this.poolCreateForm.description = "";
      this.poolCreateForm.tags = "";
    },
    resetError() {
      this.error = false
      this.errorMessage = false
    },
    closePoolCreationForm() {
      this.resetCreateForm()
      this.showCreate = false
    },
    openPoolCreationForm() {
      this.showCreate = true
    },
  },

  created() {
    this.getAllPools();
  },
}
</script>
