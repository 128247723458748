import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async checkEmail(emailAddress) {
        return await axios.post(`${apiEndpoint}/company/checkAvailableEmail`, { email: emailAddress }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    register(formData) {
        // Sending formData using multipart/form-data
        return axios.post(`${apiEndpoint}/company/register`, formData, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    login(email, password) {
        return axios.post(`${apiEndpoint}/company/login`, { email: email, password: password }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async getCompanyAccount() {
        return axios.get(`${apiEndpoint}/company/get-account`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async completeRegistration(data) {
        return axios.post(`${apiEndpoint}/company/complete-registration`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    }
}