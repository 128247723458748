<template>
  <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <span class="block sm:inline">{{ errorMessage }}</span>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-firstName">
        First name
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-firstName" type="text" v-model="formData.field_firstName">
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-lastName">
        Last name
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-lastName" type="text" v-model="formData.field_lastName">
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-phone">
        Phone
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-phone" type="tel" v-model="formData.field_phone">
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <!-- Street Address -->
    <div class="w-full px-3 mb-6">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-address">
        Street Address
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-address" type="text" v-model="formData.field_address">
    </div>

    <!-- Suburb -->
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-suburb">
        Suburb
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-suburb" type="text" v-model="formData.field_suburb">
    </div>

    <!-- State -->
    <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-state">
        State
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-state" type="text" v-model="formData.field_state">
    </div>

    <!-- Post Code -->
    <div class="w-full md:w-1/4 px-3">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-postCode">
        Post Code
      </label>
      <input class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-postCode" type="text" v-model="formData.field_postCode">
    </div>
  </div>

  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full px-3">
      <label class="block tracking-wide text-gray-800 text-sm mb-2" for="grid-visaStatus">
        Visa status
      </label>

      <select class="appearance-none block w-full text-gray-500 border border-gray-300 rounded py-3 px-4 leading-tight" id="grid-visaStatus" v-model="formData.field_visaStatus">
        <option disabled value="">Select an option</option>
        <option value="Citizen">Citizen</option>
        <option value="Permanent Resident">Permanent Resident</option>
        <option value="Visa with full working rights">Visa with full working rights</option>
        <option value="Visa that requires sponsorship">Visa that requires sponsorship</option>
        <option value="Working Holiday Visa">Working Holiday Visa</option>
        <option value="I’m not a citizen and don’t hold a visa">I’m not a citizen and don’t hold a visa</option>
        <option value="Other">Other</option>
      </select>
    </div>
  </div>


  <div class="flex flex-wrap mb-6">
    <label class="block tracking-wide text-gray-800 text-sm mb-2">
      Profile Picture (preview of you cropped image)
    </label>

    <!-- Upload Box Styling (similar to resume upload) -->
    <label class="relative flex flex-col items-center justify-center w-full h-48 bg-gray-50 border-2 border-dashed border-gray-300 hover:bg-gray-100 hover:border-gray-400 rounded-lg cursor-pointer transition ease-in-out duration-150">
      <!-- Default State (before file is uploaded) -->
      <div v-if="!this.formData.profilePicture" class="flex flex-col items-center justify-center pt-5">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-400 hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12l-4-4m0 0l-4 4m4-4v12"></path>
        </svg>
        <p class="pt-1 text-sm font-medium text-gray-600">Click to upload your profile picture</p>
      </div>



      <!-- Uploaded Image Preview -->
      <div v-else class="relative flex flex-col p-5 items-center justify-center" style="max-width: 100%; max-height: 500px; overflow: hidden;">
        <img
            :src="formData.croppedImage"
            alt="Profile Picture Preview"
            class="object-contain w-full h-auto"
            style="max-height: 100%;"
        >
      </div>

      <!-- Hidden file input -->
      <input type="file" class="hidden" @change="handleProfilePictureUpload">

    </label>

    <ImageCropperModal
        v-if="showModal"
        :image="profilePicturePreview"
        :show="showModal"
        @close="closeModal"
        @save="saveImage"
    />
  </div>


</template>

<script>
import ImageCropperModal from '@/components/modals/ImageCropperModal.vue';  // Import the standalone modal component

export default {
  components: {
    ImageCropperModal
  },
  props: ['formData'],
  inject: ['toggleLoading'],
  data() {
    return {
      error: false,
      errorMessage: "",
      profilePicturePreview: null,  // Holds the image URL
      showModal: false,    // Controls whether the modal is shown
    }
  },
  methods: {
    // Handle image upload and trigger popup
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.showModal = true; // Show the modal for adjusting the image
      } else {
        alert('Please upload a valid image file.');
      }
    },
    // Handle closing the modal
    closeModal() {
      this.showModal = false;
    },
    // Save the cropped image
    saveImage(imageData) {
      this.formData.croppedImage = imageData
      this.showModal = false;
    },
    showError(message) {
      this.error = true;
      this.errorMessage = message;

      // Clear previous timeout if it exists
      if (this.fadeTimeout) {
        clearTimeout(this.fadeTimeout);
      }

      // Hide the error after 5 seconds
      this.fadeTimeout = setTimeout(() => {
        this.error = false;
      }, 5000); // Error message stays visible for 5 seconds
    },
    initiateDefaultValues() {
      this.formData.field_visaStatus = this.formData.field_visaStatus !== undefined ? this.formData.field_visaStatus : '';
    },
    // Handle the profile picture upload
    handleProfilePictureUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        // Handle cancel event (when no file is selected)
        return; // Simply return if no file was selected
      }

      if (file && file.type.startsWith('image/')) {
        this.formData.profilePicture = file;  // Store the uploaded file in formData
        this.profilePicturePreview = URL.createObjectURL(file);  // Generate preview URL
        this.showModal = true;
      } else {
        this.showError('Please upload a valid image file.');
      }
    },
    validate() {
      this.toggleLoading(true);
      // Reset error state
      this.error = false;
      this.errorMessage = '';

      let isValid = true;

      // Validate profile picture
      if (!this.formData.profilePicture) {
        // this.showError('Please upload a picture for your profile.');
        this.errorMessage = "Please upload a picture for your profile."
        isValid = false;
      }

      // Validate visa status
      if (!this.formData.field_visaStatus) {
        // this.showError('A valid visa status is required.');
        this.errorMessage = 'A valid visa status is required.';
        isValid = false;
      }

      if (!this.formData.field_address || !this.formData.field_suburb || !this.formData.field_state || !this.formData.field_postCode ||  this.formData.field_address.length < 5) {
        // this.showError('Please enter a valid address.');
        this.errorMessage = 'Please enter a valid address.';
        isValid = false;
      }

      // Validate phone number
      if (!this.formData.field_phone) {
        // this.showError('A valid phone number is required.');
        this.errorMessage = 'A valid phone number is required.';
        isValid = false;
      }

      // Validate first and last name
      if (!this.formData.field_firstName || !this.formData.field_lastName) {
        // this.showError('Please provide both your first and last name.');
        this.errorMessage = 'Please provide both your first and last name.';
        isValid = false;
      }

      // If valid, emit the formData
      if (isValid) {
        this.$emit('submit-step2', this.formData); // Emit form data to parent
      } else {
        this.$root.notify(this.errorMessage, 'error');
      }

      this.toggleLoading(false);

      return isValid;
    }
  },
  mounted() {
    this.initiateDefaultValues();
  }
};
</script>

<style scoped>
/* Fade transition for both fade-in and fade-out */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
/* You can adjust image styles as needed */
img {
  max-width: 100%; /* Ensures the image doesn't overflow the container */
  height: auto;   /* Maintains aspect ratio */
}
</style>
