<template>
    <div>
  
      <div class="flex items-center justify-end p-3 select-none">

      </div>
  
      <div class="flex items-start">
        <div class="hidden md:flex flex-basis-20 md:flex-basis-30 flex-col p-5 mr-5 bg-white rounded-xl" style="min-width: 200px">
          <h1 class="text-lg font-bold mb-3">Filters</h1>
  
          <hr />
  
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('full_name')">
            <h1 class="my-1">Alphabetical</h1>
            <div class="">
              <svg v-if="this.sort && this.sort.name == 'full_name' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'full_name' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_total_experience')">
            <h1 class="my-1">Experience</h1>
            <div class="">
              <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_experience_company')">
            <h1 class="my-1">Current Tenure</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('loyalty')">
            <h1 class="my-1">Company tenure</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_companies')">
            <h1 class="my-1">Companies</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('salary')">
            <h1 class="my-1">Salary</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('rate')">
            <h1 class="my-1">Rate</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('sortingAvailability')">
            <h1 class="my-1">Availability</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('phone')">
            <h1 class="my-1">Phone</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'phone' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'phone' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('email')">
            <h1 class="my-1">Email</h1>
            <div>
              <svg v-if="this.sort && this.sort.name == 'email' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'email' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <hr />
          <div class="text-center mt-3 select-none">
            <span class="text-olive cursor-pointer" @click="sortProfiles('clear')">Clear filters</span>
          </div>
        </div>
  
        <div class="flex flex-col mb-5" style="width: 100%">
          <div class="flex flex-col mb-5">
            <label class="relative">
                <input v-model="this.tempSearch" v-on:keyup.enter="applySearch()"
                    class="w-full bg-white placeholder:font-italitc border border-gray-300 drop-shadow-md rounded-md py-2 pl-3 pr-10 focus:outline-none"
                    placeholder="Search for profiles" type="text" />
  
                <span v-if="this.search != ''" class="absolute inset-y-0 right-0 flex items-center pr-3" @click="clearSearch()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </span>
            </label>
          </div>
  
          <div class="flex flex-grow flex-col">
            <div v-if="!this.hasFinishedLoading" class="flex flex-grow flex-col">
              <div class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
                <span>Please wait, loading your profiles...</span>
              </div>
            </div>
            <div v-if="this.profiles.length == 0 && this.search == '' && !this.isLoadingData" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
              <span>You don't have any parsed profiles on your account. Please use the <span class="font-bold">Add Profile</span> button above to start adding profiles.</span>
            </div>
            <div v-if="this.profiles.length == 0 && this.search != ''" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
              <span>No profiles are matching your search criteria.</span>
            </div>
            <BigCardCompany v-for="profile in profiles" :profile="profile" :key="profile.hash" @remove-profile="removeProfileFromAccount" />
          </div>
  
          <nav v-if="this.hasFinishedLoading" class="text-center my-5">
            <ul class="inline-flex -space-x-px">
              <li>
                <button class="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="prevPage()">Previous</button>
              </li>
              <li>
                <div class="py-2 px-3 ml-0 select-none">
                  Showing page {{ this.page }} of {{ this.maxPages }} ({{this.totalProfiles }} total profiles)
                </div>
              </li>
              <li>
                <button class="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="nextPage()">Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  let apiEndpoint = process.env.VUE_APP_API_BASEURL
  
  import BigCardCompany from '@/components/marketplace/company/BigCardCompany'
  import ResumeUpload from '@/components/ResumeUpload'
  import _ from 'lodash'
  
  export default {
    name: 'Profiles',
    components: {
      BigCardCompany,
      ResumeUpload
    },
  
    data() {
      return {
        isLoadingData: false,
        tempSearch: '',
        search: '',
        page: 1,
        maxPages: 1,
        totalProfiles: 0,
        profiles: [],
        sort: null,
        showUploadScreen: false,
        hasFinishedLoading: false,
      };
    },
  
    methods: {
      clearSearch() {
        this.tempSearch = ''
        this.search = ''
        // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
        this.page = 1;
        this.getPagedProfiles();
      },
      applySearch() {
        this.search = this.tempSearch
        // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
        this.page = 1;
        this.getPagedProfiles();
      },
      nextPage() {
        if (this.page < this.maxPages) {
          this.page = (this.page + 1);
          this.getPagedProfiles();
          // Scroll page back up
          window.scrollTo(0, 0)
        }
      },
      prevPage() {
        if (this.page > 1) {
          this.page = (this.page - 1);
          this.getPagedProfiles();
          // Scroll page back up
          window.scrollTo(0, 0)
        }
      },
      async getPagedProfiles() {
        this.isLoadingData = true
        try {
          let sortField = (this.sort == null) ? '' : this.sort.name
          let sortOrder = (this.sort == null) ? '' : this.sort.order
          let searchTerm = this.search
  
          let response = await fetch(apiEndpoint + "/marketplace.php?getPagedProfiles&page=" + this.page + "&sortField=" + sortField + "&sortOrder=" + sortOrder + '&search=' + searchTerm, {
            headers: {
              'Authorization': 'Bearer ' +  localStorage.getItem('companyToken'),
            },
          })
          let res = await response.json();

          // Update profiles
          this.profiles = res.profileData
          // Update max pages
          this.maxPages = res.totalPages
          // Save total profiles
          this.totalProfiles = res.totalProfiles
          // Mark finished loading
          this.hasFinishedLoading = true
          this.isLoadingData = false
        } catch (error) {
          console.log(error)
          this.$router.push('Logout')
        }
      },
      removeProfileFromAccount(profileHash) {
        // Remove from array
        _.remove(this.profiles, function(n) {
          return n.profile_hash == profileHash
        });
  
        // Remove from database
        let postData = {
          ph: profileHash
        }
  
        try {
          fetch(apiEndpoint + "/profile.php?removeProfileFromAccount", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
            },
            body: JSON.stringify(postData),
          })
          .then(response => response.json())
          .then(data => {
            //
          })
        } catch (error) {
          // Show error
        }
      },
      sortProfiles (field) {
        if (field == 'clear') {
          // this.profiles = _.orderBy(this.profiles, 'parse_timestamp', 'desc');
          this.sort = null;
          this.getPagedProfiles();
          return
        }
  
        // If current sort is null, we sort by this field in a descending order
        if (this.sort == null) {
          // this.profiles = _.orderBy(this.profiles, field, 'desc');
          // this.profiles = _.orderBy(this.profiles, [( o ) => { return o[field] || ''}], ['desc']); 
    
          // Set the new sort settings
          this.sort = {
            name: field,
            order: 'desc'
          }
        } else {
          // If the current sorted field is not the same sort by the new field in a descending order
          if (this.sort.name != field) {
            // this.profiles = _.orderBy(this.profiles, field, 'desc');
            // this.profiles = _.orderBy(this.profiles, [( o ) => { return o[field] || ''}], ['desc']); 
            // Set the new sort settings
            this.sort = {
              name: field,
              order: 'desc'
            }
          } else {
            // Otherwise switch the type and order by the new settings
            let newOrder = this.sort.order == 'asc' ? 'desc' : 'asc'
  
            // this.profiles = _.orderBy(this.profiles, field, newOrder);
            // this.profiles = _.orderBy(this.profiles, [( o ) => { return o[field] || ''}], [newOrder]); 
            // Set the new sort settings
            this.sort = {
              name: field,
              order: newOrder
            }
          }
        }
  
        this.getPagedProfiles();
      }
    },
  
    created() {
      this.getPagedProfiles();
    },
  }
  </script>
  