import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    register(formData) {
        // Sending formData using multipart/form-data
        return axios.post(`${apiEndpoint}/contractor/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    getProfilePictureLink(pictureHash) {
        return apiEndpoint + '/pictures/' + pictureHash + '-cropped.jpg';
    },
    async checkEmail(emailAddress) {
        return await axios.post(`${apiEndpoint}/contractor/checkAvailableEmail`, { email: emailAddress }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async updateContractorProfile(profileData) {
        return axios.post(`${apiEndpoint}/contractor/update-profile`, profileData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    }
}