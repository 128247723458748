<template>
    <div class="flex w-100 bg-white rounded-xl mb-5 hover:shadow-2xl">
        <!-- <ProfileModal ref="profileModal"></ProfileModal> -->
        <NewProfileModal ref="newProfileModal"></NewProfileModal>
        <div class="flex flex-col w-5/6 p-5 cursor-pointer" @click="openProfile()">
            <div class="flex flex-col flex-grow mb-2">
                <div class="text-olive font-bold text-xl">{{ this.profile.full_name }} <span class="text-xs" v-if="this.profile.uuid != null">({{ this.profile.uuid }})</span></div>
                <div v-if="this.profile.current_role != null">{{ this.profile.current_role }}</div>
                <div v-if="this.profile.location != null">{{ this.profile.location }}</div>
                <div v-if="this.profile.talentPools.length != 0" class="flex">
                    <div class="mr-2">Talent Pools</div>
                    <div class="flex">
                        <div v-for="pool in this.profile.talentPools" :key="pool.talent_pool_hash" :index="index" class="px-2 bg-sky-500 text-white mr-2">{{  pool.name }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="flex mt-3">
                <div class="flex flex-basis-30 font-bold">Company</div>
                <div class="flex flex-basis-70">{{ this.profile.current_company ? this.profile.current_company : 'Not employed' }}</div>
            </div>
            <div class="flex">
                <div class="flex flex-basis-30 font-bold">Current tenure</div>
                <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.updated_experience_company) }}</div>
            </div>
            <div class="flex">
                <div class="flex flex-basis-30 font-bold">Total experience</div>
                <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.updated_total_experience) }}</div>
            </div>
            <div class="flex">
                <div class="flex flex-basis-30 font-bold">Companies</div>
                <div class="flex flex-basis-70">{{ this.profile.total_companies }} <span class="text-base flex items-center" v-if="this.profile.companies != null">&nbsp;({{ JSON.parse(this.profile.companies).join(', ') }})</span></div>
            </div>
            <div class="flex">
                <div class="flex flex-basis-30 font-bold">Company tenure</div>
                <div class="flex flex-basis-70">{{ $filters.screenedDate(this.profile.loyalty) }}</div>
            </div>
            <!-- <div class="flex" v-if="this.profile.employeeType == 0">
                <div class="flex flex-basis-30 font-bold">Salary</div>
                <div class="flex flex-basis-70">{{ $filters.screenedSalary(this.profile.salary) }}</div>
            </div>
            <div class="flex" v-if="this.profile.employeeType == 1">
                <div class="flex flex-basis-30 font-bold">Rate</div>
                <div class="flex flex-basis-70">{{ $filters.screenedRate(this.profile.rate) }}</div>
            </div> -->
            <!-- <div class="flex">
                <div class="flex flex-basis-30 font-bold">Availability</div>
                <div class="flex flex-basis-70">{{ $filters.screenedAvailability(this.profile.availability) }}</div>
            </div> -->
            <div v-if="this.profile.tags" class="flex mt-2">
                <div class="flex flex-basis-30 font-bold">Tags</div>
                <div class="flex flex-basis-70 flex-wrap">
                    <div v-for="item in this.profile.tags" :key="item" class="ml-2 mr-2 select-none my-1 text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-sky-500 text-white rounded-full" >
                        {{ item.tag }} ({{ item.tag_count }})
                    </div>
                </div>
            </div>
            <div class="flex" v-if="this.profile.email">
                <div class="flex flex-basis-30 font-bold">Email</div>
                <div class="flex flex-basis-70">{{ $filters.screenedEmail(this.profile.email) }}</div>
            </div>
            <div class="flex" v-if="this.profile.phone">
                <div class="flex flex-basis-30 font-bold">Phone</div>
                <div class="flex flex-basis-70">{{ $filters.screenedPhone(this.profile.phone) }}</div>
            </div>
            <div class="flex" v-if="this.profile.linkedin_url">
                <div class="flex flex-basis-30 font-bold">Linkedin</div>
                <div class="flex flex-basis-70">{{ $filters.screenedLinkedin(this.profile.linkedin_url) }}</div>
            </div>
            <div class="flex" v-if="this.profile.visa">
                <div class="flex flex-basis-30 font-bold">Visa status</div>
                <div class="flex flex-basis-70">{{ this.profile.visa }}</div>
            </div>
        </div>
        <div class="flex flex-col p-5 w-3/12">
            <div class="flex self-end mb-1">
                <!-- <div class="w-5 h-5 rounded-full" v-bind:class="{'bg-yellow-500': this.profile.trafficlight_status == '0',  'bg-red-500': this.profile.trafficlight_status == '1',  'bg-green-500': this.profile.trafficlight_status == '2'}">&nbsp;</div> -->
                <div v-if="this.profile.trafficlight_status == 1" class="w-5 h-5 rounded-full bg-green-500">&nbsp;</div>

                <div class="px-1 text-black" @click="emitRemoveProfile(this.profile.profile_hash)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </div>
            </div>
            <div class="hidden rounded-full h-20 w-20 flex mb-3 text-olive font-bold text-3xl border-2 border-olive">{{ $filters.initials(this.profile.full_name) }}</div>
            <div class="mt-3 flex flex-col my-auto">
                <div v-if="this.profile.availability != null" class="flex flex-row text-black hover:text-olive">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
                    </svg>

                    
                    <span class="align-middle" v-if="this.profile.availability > 1">Available from <span class="font-bold">{{ $filters.screenedAvailability(this.profile.availability) }}</span></span>
                    <span class="" v-else>Available in <span class="font-bold">{{ $filters.screenedAvailability(this.profile.availability) }}</span></span>
                </div>
                <div div v-if="this.profile.salary != null" class="flex flex-row mt-2 text-black hover:text-olive">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-1">
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>


                    <span><span class="font-bold">{{ $filters.screenedSalary(this.profile.salary) }}</span> desired salary</span>
                </div>
                <div div v-if="this.profile.rate != null" class="flex flex-row mt-2 text-black hover:text-olive">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 mr-1">
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>


                    <span><span class="font-bold">{{ $filters.screenedRate(this.profile.rate) }}</span> hourly rate</span>
                </div>

                <div class="flex flex-row mt-2 text-black hover:text-olive" @click="downloadResume">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>


                    <span class="cursor-pointer select-none">{{ downloadResumeButtonText }}</span>

                </div>

            </div>
            
            <div class="text-center text-xs self-end">
                Parsed on {{ this.profile.parse_date }}
            </div>
        </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
// import ProfileModal from '@/components/modals/ProfileModal'
import NewProfileModal from '@/components/modals/NewProfileModal.vue'

export default {
    name: 'BigCard',
    components: {
        // ProfileModal,
        NewProfileModal
    },
    data() {
        return {
            downloadResumeButtonText: 'Download resume',
        }
    },
    props: ['profile', 'talentPool'],
    methods: {
        openProfile() {
            window.scrollTo(0, 0)
            // this.$refs.profileModal.openModal(this.profile);
            this.$refs.newProfileModal.openModal(this.profile);
        },
        emitRemoveProfile(ph) {
            this.$emit('remove-profile', ph)
        },
        async downloadResume() {
            this.downloadResumeButtonText = 'Downloading...'
            let ph = this.profile.profile_hash
            await fetch(apiEndpoint + '/profile.php?downloadResume&ph=' + ph, {
                headers: {
                    'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                }
            }).then(function(response) {
                // for each response header, log an array with header name as key
                const contentType = response.headers.get('content-type')
                let extension
                switch (contentType) {
                    case 'application/pdf':
                        extension = '.pdf'
                        break;
                    
                    case 'application/msword':
                        extension = '.doc'
                        break;

                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        extension = '.docx'
                        break;
                }

                const outputFileName = ph + '.' + extension
                response.blob().then(function(myBlob) {
                    const url = window.URL.createObjectURL(myBlob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', outputFileName);
                    document.body.appendChild(link);
                    link.click();
                })
            });

            this.downloadResumeButtonText = 'Download resume'
        }
    }
}
</script>