<template>
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-3 md:px-8 pt-6 pb-4 md:pb-0 mb-4 flex flex-col w-11/12 md:w-1/2">
        <div class="-mt-6 -ml-3 md:-ml-8 -mr-3 md:-mr-8 rounded-t-xl bg-sky-500 pt-4 pl-6 md:pl-8">
          <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-50">Registration</div>
        </div>

        <div class="px-4 py-8">
          <!-- Step Navigation with ">" symbol between steps -->
          <div v-if="currentStep < 2" class="flex flex-wrap justify-between items-center mb-8">
            <span
              v-for="(step, index) in steps.slice(0, 2)"
              :key="index"
              class="flex items-center"
            >
              <span
                :class="[
                  'md:px-4 md:py-2 rounded-md',
                  currentStep === index ? 'text-sm md:text-sm px-4 py-2 bg-sky-500 text-white' : 'text-gray-700 border',
                  isStepCompleted(index) ? 'text-xs md:text-sm px-1 py-1 bg-green-500 text-white' : '',
                  !isStepAccessible(index) ? 'text-xxs md:text-sm px-1 py-1 opacity-50 cursor-not-allowed' : 'cursor-pointer'
                ]"
                @click="goToStep(index)"
                :disabled="!isStepAccessible(index)"
              >
                {{ step.name }}
              </span>
  
              <span v-if="index < steps.length - 2" class="mx-2 hidden md:flex items-center justify-center text-gray-400 text-xs">- - ></span>
            </span>
          </div>
  
          <transition name="fade">
            <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <span class="block sm:inline">{{ this.errorMessage }}</span>
            </div>
          </transition>
  
          <!-- Step Components -->
          <StepOne v-if="currentStep === 0" ref="step0" :formData="formData.stepOne" @submit-step1="handleStep1Data" />
          <StepTwo v-if="currentStep === 1" ref="step1" :formData="formData.stepTwo" @submit-step2="handleStep2Data" />
          <ThankYou v-if="currentStep === 2" />
  
          <!-- Navigation Buttons -->
          <div class="flex justify-between mt-8">
            <!-- Previous Button (only shown after the first step) -->
            <button
              v-if="currentStep > 0 && currentStep < 2"
              @click="previousStep"
              class="border border-sky-500 hover:bg-sky-100 text-gray-500 py-2 px-4 rounded"
            >
              Previous
            </button>
  
            <!-- Next or Submit Button -->
            <div class="ml-auto">
              <button
                v-if="currentStep < steps.length - 2"
                @click="validateStepAndProceed"
                class="bg-sky-500 hover:bg-sky-400 text-white px-4 py-2 rounded-md"
              >
                Next
              </button>
              <button
                v-if="currentStep === steps.length - 2"
                @click="submitForm"
                class="bg-green-500 text-white px-4 py-2 rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
    import StepOne from '@/components/marketplace/company/register/Step1.vue';
    import StepTwo from '@/components/marketplace/company/register/Step2.vue';
    import ThankYou from '@/components/marketplace/company/register/ThankYou.vue';
  
    import companyService from '@/services/marketplace/company.service';
    
    export default {
      components: { StepOne, StepTwo, ThankYou },
      inject: ['toggleLoading'],
      data() {
        return {
          currentStep: 0,
          completedSteps: [], // Track completed steps
          steps: [
            { name: 'Login Details' },
            { name: 'Company Details' },
            { name: 'Thank You' }
          ],
          formData: {
            stepOne: {},
            stepTwo: {},
            stepThree: {},
            stepFour: {}
          },
          error: false,
          errorMessage: '',
          submitDisabled: false
        };
      },
      methods: {
          // Check if the step is completed
          isStepCompleted(index) {
              return this.completedSteps.includes(index);
          },
        
          // Check if the step is accessible (either completed or the current step)
          isStepAccessible(index) {
              return index <= this.currentStep || this.isStepCompleted(index);
          },
        
          // Navigate to a step (if accessible)
          goToStep(index) {
              if (this.isStepAccessible(index)) {
                  this.currentStep = index;
              }
          },
  
  
      // Handle emitted data from Step 1
      handleStep1Data(stepOneData) {
        this.formData.stepOne = stepOneData;  // Store the data for Step 1
      },
  
      // Handle emitted data from Step 2
      handleStep2Data(stepTwoData) {
        this.formData.stepTwo = stepTwoData;  // Store the data for Step 2
      },
      // Validate the current step and proceed if valid
      async validateStepAndProceed() {
        const currentStepComponent = this.$refs[`step${this.currentStep}`];
        if (currentStepComponent && await currentStepComponent.validate()) {
          this.nextStep();
        }
      },
      // Navigate to the next step and mark the current one as completed
      nextStep() {
        if (!this.completedSteps.includes(this.currentStep)) {
          this.completedSteps.push(this.currentStep);
        }
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
        }
      },
      // Navigate to the previous step
      previousStep() {
        if (this.currentStep > 0) {
          this.currentStep--;
        }
      },
      async submitForm() {
        this.toggleLoading(true);
        if (this.submitDisabled) { return }
        const currentStepComponent = this.$refs[`step${this.currentStep}`];

        if (currentStepComponent && currentStepComponent.validate()) {
          try {
            // Combine all steps into a single object
            const flatFormData = {
              ...this.formData.stepOne,
              ...this.formData.stepTwo
            };

            companyService.register(flatFormData)
            .then(response => {
                if (response.data && response.data.registrationError) {
                this.error =  true;
                this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
          //       this.submitDisabled = true
                } else {
                    this.currentStep = 2;
                }
              this.toggleLoading(false);
            })
            .catch(error => {
          //     // Handle error if the API call fails
              this.error =  true;
              this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
              // this.submitDisabled = true
              this.toggleLoading(false);
            });
          } catch (error) {
            this.error =  true;
            this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
          //   this.submitDisabled = true
            this.toggleLoading(false);
          }
        }
      }
    }
  };
  
  </script>
    
    <style scoped>
    button {
    transition: all 0.3s ease;
  }
  </style>
  