// Import the components
import ContractorRegistration from '../views/marketplace/contractor/Register.vue'
import ContractorForgotPassword  from '../views/marketplace/contractor/ForgotPassword.vue'
import CompanyRegistration from '../views/marketplace/company/Register.vue'
import CompanyDashboard from '../views/marketplace/company/Dashboard.vue'
// Define the routes
const marketplaceRoutes = [
    // Contractor registration
    {
        path: '/contractor/register',
        name: 'ContractorRegistration',
        component: ContractorRegistration,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    // Contractor Forgot password
    {
        path: '/contractor/forgot-password',
        name: 'ContractorForgotPassword',
        component: ContractorForgotPassword,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    {
      path: '/company/register',
      name: 'CompanyRegistration',
      component: CompanyRegistration,
      meta: {
        companyHeader: true,
        breadcrumbs: false
      }
    },
    {
      path: '/company/dashboard',
      name: 'CompanyDashboard',
      component: CompanyDashboard,
      meta: {
        companyHeader: true,
        breadcrumbs: false
      }
    }
]

export default marketplaceRoutes;