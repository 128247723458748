<template>
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-11/12 md:w-1/2">
        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span class="block sm:inline">You have entered an invalid username or password</span>
        </div>
        <div class="mb-4">
          <label class="block text-gray-800 text-sm mb-2" for="username">
            Email address
          </label>
          <input class="shadow appearance-none border-gray-300 rounded w-full py-2 px-3 text-gray-500" id="email" v-model="email" type="text">
        </div>
        <div class="mb-6">
          <label class="block text-gray-800 text-sm mb-2" for="password">
            Password
          </label>
          <input class="shadow appearance-none border-gray-300 border-red rounded w-full py-2 px-3 text-gray-500 mb-3" id="password" v-model="password" type="password">
        </div>
        <div class="flex items-end justify-end">
          <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitLogin">
            {{ SignInButtonText }}
          </button>
        </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email:"",
      password: "",
      error: false,
      SignInButtonText: "Sign In"
    };
  },
  methods: {
    submitLogin() {
      this.error = false
      if (this.email != "" && this.password != "") {
        // Build submission form
        let formData = new FormData();
        formData.append('emailAddress', this.email);
        formData.append('sPass', this.password);

        fetch(apiEndpoint + "/auth.php", {
          body: formData,
          method: "POST"
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Set state
            this.$store.commit('setLoggedIn', true)

            // Write token to localStorage
            localStorage.setItem('screenedToken', data.token);

            // Redirect to profiles
            this.$router.push('profiles') 
          } else {
            // Clear values
            this.email = ""
            this.password = ""

            // Set state
            this.$store.commit('setLoggedIn', false)
            // Remove token from local storage
            localStorage.removeItem('screenedToken');

            // Set error to true
            this.error = true
          }
        })
      } else {
        this.error = true
      }
    },
    logoutUser() {  
        // Set state
        this.$store.commit('setLoggedIn', false)
        // Remove localstorage
        localStorage.removeItem('screenedToken');

        // Redirect to login
        this.$router.push('Login') 
    },
  },
  created() {
    // this.logoutUser();
  },
}
</script>
