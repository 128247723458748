<template>
    <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
        <span class="block sm:inline">{{ this.errorMessage }}</span>
    </div>


    <div class="flex items-center justify-center w-full">
        <label class="relative flex flex-col items-center justify-center w-full h-32 bg-gray-50 border-2 border-dashed border-gray-300 hover:bg-gray-100 hover:border-gray-400 rounded-lg cursor-pointer transition ease-in-out duration-150">

            <!-- Uploaded file display -->
            <div v-if="this.formData && this.formData.isFileUploaded" class="flex flex-col items-center justify-center">
                <svg class="w-6 h-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span class="text-sm text-gray-700">{{ this.formData.uploadedFile.name }}</span>
                <button @click="cancelUpload" class="absolute top-2 right-2 text-gray-400 hover:text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>

            <!-- Default state (before file upload) -->
            <div v-else class="flex flex-col items-center justify-center pt-5">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-gray-400 hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12l-4-4m0 0l-4 4m4-4v12"></path>
                </svg>
                <p class="pt-1 text-sm font-medium text-gray-600">Click to upload your resume</p>
            </div>

            <input type="file" class="hidden" @change="fileUpload">
        </label>
    </div>
</template>



<script>

export default {
    props: ['formData'],
    data() {
        return {
            // isFileUploaded: false,
            // uploadedFile: null,
            fileSuccess: false,
            error: false,
            errorMessage: '',
        }
    },
    methods: {
        fileUpload(event) {
            const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
            this.fileSuccess = false
            this.error = false
            this.errorMessage = ''
            
            
            let currentFile = event.target.files[0]

            if (!currentFile) {
                // No file was selected (user clicked cancel)
                return;
            }

            if (allowedTypes.includes(currentFile.type)) {
                this.formData.uploadedFile = event.target.files[0]
                this.formData.isFileUploaded = true
            } else {
                this.error = true
                this.errorMessage = 'Invalid file selected. Only PDF, DOC and DOCX files are allowed.'
            }
        },
        cancelUpload() {
            this.formData.uploadedFile = null
            this.formData.isFileUploaded = false
            this.error = false
            this.errorMessage = ''
        },
        showError(message) {
        this.error = true;
        this.errorMessage = message;
  
        // Clear previous timeout if it exists
        if (this.fadeTimeout) {
          clearTimeout(this.fadeTimeout);
        }
  
        // Hide the error after 5 seconds
        this.fadeTimeout = setTimeout(() => {
          this.error = false;
        }, 5000); // Error message stays visible for 5 seconds
      },
        validate() {
            // Reset error state
            this.error = false
            this.errorMessage = ''

            let isValid = true

            if (!this.formData.isFileUploaded) {
                // this.showError('You must select your resume file in PDF, DOC or DOCX format.');
                this.errorMessage = 'You must select your resume file in PDF, DOC or DOCX format.';
                isValid = false;
            }

             // If valid, emit the formData
            if (isValid) {
                this.$emit('submit-step4', this.formData); // Emit form data to parent
            } else {
                this.$root.notify(this.errorMessage, 'error');
            }

            return isValid
        }
    }
}
</script>