<template>
    <!-- <div class="test-modal"> -->
    <div class="profileModal top-20 absolute hidden shadow-xl w-4/6 justify-center items-center" :class="{visibleModal: isVisible}">
        <div class="flex flex-col modal-content p-5 bg-white z-20 relative rounded-xl border-2 border-olive">
            <div class="flex items-center px-5 justify-between">
                <div>
                    <h1 class="text-xl font-bold select-none">Add profiles</h1>
                </div>
                <div class="cursor-pointer text-olive" @click="closeModal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            </div>
            <div class="pt-2 pb-3">
                <hr />
            </div>

            <div class="flex flex-col">
                <div class="flex flex-col my-5">
                    <input v-model="this.tempSearch" v-on:keyup.enter="applySearch()" class="focus:ring-0 focus:border-olive hover:border-olive placeholder:italic placeholder:text-gray-400 block w-full border border-gray-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 sm:text-sm" placeholder="Search for profiles..." type="text" name="search" autocomplete="off" />
                </div>
                <div class="flex my-5 mx-2 justify-between">
                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_total_experience')">Experience</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>

                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_experience_company')">Tenure</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>

                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('loyalty')">Company tenure</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>

                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_companies')">Companies</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>

                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('salary')">Salary</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>

                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('rate')">Rate</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>
    
                    <div class="flex hover:text-olive">
                        <div class="cursor-pointer select-none hover:text-olive" @click="sortProfiles('sortingAvailability')">Availability</div>
                        <div class="ml-2">
                            <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div v-if="noProfilesLeft">No other profiles available</div>
                    <div class="flex flex-col flex-grow basis-3/6">
                        <transition name="fade" v-for="profile in profiles" :profile="profile" :key="profile.hash">
                            <div v-if="this.profileNotInPool(profile.profile_hash) && isSearchFiltered(profile.full_name)" class="flex flex-col w-100 bg-white rounded-xl mb-5 hover:shadow-md cursor-pointer border-2 border-gray-100 p-5" @click="addProfileToPool(profile)">
                            <!-- <div :class="[this.profileInPool(profile.profile_hash) ? 'border 2 border-olive cursor-not-allowed' : '', 'cursor-pointer hover:shadow-2xl border-gray-200 border-2']" class="flex w-100 rounded-xl mb-5 bg-white p-5"> -->
                                <!-- {{ profile }} -->
                                <div class="flex flex-col flex-grow">
                                    <div class="text-olive font-bold text-md">{{ profile.full_name }}</div>
                                    <div class="text-md">{{ profile.current_role }}</div>
                                    <div class="text-md">Sydney</div>
                                </div>

                                <div class="flex mt-3">
                                    <div class="flex flex-basis-30 font-bold">Company</div>
                                    <div class="flex flex-basis-70 text-xl">{{ profile.current_company }}</div>
                                </div>
                                <div class="flex mt-2">
                                    <div class="flex flex-basis-30 font-bold">Current tenure</div>
                                    <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(profile.experience_company) }}</div>
                                </div>
                                <div class="flex mt-2">
                                    <div class="flex flex-basis-30 font-bold">Total experience</div>
                                    <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(profile.total_experience) }}</div>
                                </div>
                                <div class="flex mt-2">
                                    <div class="flex flex-basis-30 font-bold">Companies</div>
                                    <div class="flex flex-basis-70 text-xl">{{ profile.total_companies }} <span v-if="profile.companies != null">&nbsp;({{ JSON.parse(profile.companies).join(', ') }})</span></div>
                                </div>
                                <div class="flex mt-2">
                                    <div class="flex flex-basis-30 font-bold">Company tenure</div>
                                    <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(profile.loyalty) }}</div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
import _ from 'lodash'

export default {
    components: {},
    data() {
        return {
            isVisible: false,
            tempSearch: '',
            search: '',
            page: 1,
            maxPages: 1,
            totalProfiles: 0,
            talentPool: {},
            profiles: [],
            poolMembers: [],
            sort: null,
        }
    },
    methods: {
        openModal(tp) {
            this.isVisible = true
            this.talentPool = tp

            // Reset all
            this.getPoolMembers();
            this.search = ''
            this.sort = null
        },
        closeModal() {
            this.isVisible = false
            this.$emit('refreshPool');
        },
        clearSearch() {
            this.tempSearch = ''
            this.search = ''
            // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
            this.page = 1;
            this.getPagedProfiles();
        },
            applySearch() {
            this.search = this.tempSearch
            // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
            this.page = 1;
            this.getPagedProfiles();
        },
        async getPagedProfiles() {
            // this.isLoadingData = true
            try {
                let sortField = ''
                let sortOrder = '';

                // let sortField = (this.sort == null) ? '' : this.sort.name
                // let sortOrder = (this.sort == null) ? '' : this.sort.order
                let searchTerm = this.search

                let response = await fetch(apiEndpoint + "/profile.php?getPagedProfiles&page=" + this.page + "&sortField=" + sortField + "&sortOrder=" + sortOrder + '&search=' + searchTerm, {
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                })
                let res = await response.json();
                // Update profiles
                this.profiles = res.profileData
                // Update max pages
                this.maxPages = res.totalPages
                // Save total profiles
                this.totalProfiles = res.totalProfiles
                // Mark finished loading
                // this.hasFinishedLoading = true
                // this.isLoadingData = false
            } catch (error) {
                this.$router.push('Logout')
            }
        },
        async getAllProfiles() {
            try {
                // let response = await fetch(apiEndpoint + "/profile.php?getAll");
                let response = await fetch(apiEndpoint + "/profile.php?getAll", {
                    headers: {
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                })
                this.profiles = await response.json();
            } catch (error) {
                // console.log(error);
            }
        },
        async getPoolMembers() {
            // let response = await fetch(apiEndpoint + "/talentpool.php?getMembers&tph=" + this.$route.params.poolHash);
            let response = await fetch(apiEndpoint + "/talentpool.php?getMembers&tph=" + this.$route.params.poolHash, {
                headers: {
                    'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                },
            })
            this.poolMembers = await response.json();
            // this.hasFinishedLoadingMembers = true
        },
        profileNotInPool(profileHash) {

            return !_.some(this.poolMembers, ['profile_hash', profileHash])
        },
        isSearchFiltered(fullName) {
            if (this.search === "") {
                return true;
            } else {
                if (fullName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                    return true;
                }
            }

            return false;
        },
        addProfileToPool(profile) {
            this.poolMembers.push(profile)
            let postData = {
                tph: this.talentPool.talent_pool_hash,
                ph: profile.profile_hash
            }

            try {
                fetch(apiEndpoint + "/talentpool.php?addProfileToPool", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
                    },
                    body: JSON.stringify(postData),
                    })
                .then(response => response.json())
                .then(data => {
                    //
                })
            } catch (error) {
                // Show error
            }
        },
        sortProfiles(field) {
            if (field == 'clear') {
                this.profiles = _.orderBy(this.profiles, 'parse_timestamp', 'DESC');
                this.sort = null;
                return
            }

            // If current sort is null, we sort by this field in a descending order
            if (this.sort == null) {
                this.profiles = _.orderBy(this.profiles, field, 'desc');
        
                // Set the new sort settings
                this.sort = {
                name: field,
                order: 'desc'
                }
            } else {
                // If the current sorted field is not the same sort by the new field in a descending order
                if (this.sort.name != field) {
                this.profiles = _.orderBy(this.profiles, field, 'desc');
                // Set the new sort settings
                this.sort = {
                    name: field,
                    order: 'desc'
                }
                } else {
                // Otherwise switch the type and order by the new settings
                let newOrder = this.sort.order == 'asc' ? 'desc' : 'asc'

                this.profiles = _.orderBy(this.profiles, field, newOrder);
                // Set the new sort settings
                this.sort = {
                    name: field,
                    order: newOrder
                }
                }
            }
        },
    },
    created() {
        // this.getAllProfiles();
        this.getPagedProfiles();
    }
}
</script>

<style>
/* .hiddenModal {
    visibility: hidden;
}*/

.disabledSave {
    cursor: not-allowed !important;
    pointer-events: all !important;
    background-color: gray !important;
}

.visibleModal {
    display: block !important;
    /* visibility: visible !important; */
}
/* HIDE ARROWS FROM NUMBER INPUT */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>