<template>
  <TalentBenchModal ref="talentBenchModal"></TalentBenchModal>
  <div class="pt-7">
    <div v-if="!this.isEditing" class="flex flex-col bg-white rounded-xl p-5 w-100">

      <div v-if="!this.isEditing" class="flex items-center justify-between" id="talentPoolDetails">
        <div class="flex flex-col">
          <h1 class="text-2xl text-olive font-bold">{{ talentpool.name }}</h1>
          <div class="text-xl">{{ talentpool.location }}</div>
          <div class="pt-2">{{ talentpool.description }}</div>
        </div>

        <div class="flex">
          <div class="flex flex-col justify-items-center items-center cursor-pointer text-olive mr-2" @click="exportBench">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            <span class="ml-1 mr-1 font-bold select-none">Export bench</span>
          </div>
          <div class="flex flex-col justify-items-center items-center cursor-pointer text-olive mr-2" @click="exportPool">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            <span class="ml-1 mr-1 font-bold select-none">Export</span>
          </div>

          <div class="flex flex-col justify-items-center items-center cursor-pointer text-olive" @click="switchEdit">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <span class="ml-1 mr-1 font-bold select-none">Edit</span>
          </div>
        </div>
      </div>

      <TalentPoolStats />

      <div class="mt-7 flex-col">
        <hr />
          <div class="flex justify-center mt-3 font-bold text-xl text-olive select-none">Tags</div>
          <div class="mt-4 flex flex-wrap text-center">
            <div v-for="item in this.talentpool.tags" :key="item" class="ml-2 mr-2 select-none my-1 text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-sky-500 text-white rounded-full" >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="this.isEditing" class="flex flex-col bg-white mb-5 rounded-xl p-4">
        <div class="flex items-center justify-between text-olive">
          <div class="text-black pt-5 pb-2 text-xl font-bold">Edit talent pool</div>
        </div>

        <hr />

        <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-5" role="alert">
          <strong class="font-bold">Error! </strong>
          <span v-if="!this.errorMessage" class="block sm:inline">Something went wrong when attempting to create the new talent pool, please try again.</span>
          <span v-if="this.errorMessage" class="block sm:inline">{{ this.errorMessage }}</span>
          <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
          </span>
        </div>

        <div class="flex pt-5 space-x-5">
          <div class="flex-1 flex-col">
            <div class="font-bold py-1">Name</div>
            <div>
              <input v-model="this.talentPoolEditForm.name" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
            </div>
          </div>
          <div class="flex-1 flex-col">
            <div class="font-bold py-1">Location</div>
            <div>
              <input v-model="this.talentPoolEditForm.location" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-2">
          <div class="font-bold py-1">Description</div>
          <div>
            <textarea v-model="this.talentPoolEditForm.description" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" rows="3"></textarea>
          </div>
        </div>

        <div class="flex flex-col mt-2">
          <div class="font-bold py-1">Tags</div>
          <div class="my-3">
            <div v-for="item in this.talentPoolEditForm.tags" :key="item" class="ml-2 mr-2 select-none my-1 text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-sky-500 text-white rounded-full" >
              {{ item }}
              <span class="ml-3 text-sm cursor-pointer" @click="removeTag(item)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </span>
            </div>
            <div v-if="this.talentPoolEditForm.tags.length == 0">There are no tags added to this talent pool yet.</div>
          </div>
          <div class="flex flex-row">
            <input v-on:keyup.enter="addTag" v-model="this.tag" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" placeholder="Type a tag and press enter"/>
          </div>
        </div>

        <div class="flex mt-8 justify-between">
          <button class="bg-sky-500 px-5 py-2 rounded-md text-white font-bold hover:shadow-md" @click="cancelEditPool">Cancel</button>
          <button class="bg-sky-500 px-5 py-2 rounded-md text-white font-bold hover:shadow-md" @click="updatePool">Update talent pool</button>
        </div>
      </div>

      <hr class="my-5" />

      <div>
        <div class="flex items-center justify-between p-3 select-none">
          <h1 class="font-bold text-3xl text-black mb-3">Profiles ({{ poolMembers.length }})</h1>
          <div class="flex flex-col items-center text-olive cursor-pointer" @click="this.openAddModal">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
              </svg>
            </div>
            <div class="text-center ml-3 cursor-pointer font-bold">
              Add profiles
            </div>
          </div>
        </div>

        <div class="flex items-start">
          <div class="hidden md:flex flex-basis-20 md:flex-basis-30 flex-col p-5 mr-5 bg-white rounded-xl" style="min-width: 200px;">
            <h1 class="text-lg font-bold mb-3">Filters</h1>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('full_name')">
              <h1 class="my-2">Alphabetical</h1>
              <div class="">
                <svg v-if="this.sort && this.sort.name == 'full_name' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'full_name' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_total_experience')">
              <h1 class="my-2">Experience</h1>
              <div class="">
                <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'updated_total_experience' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('updated_experience_company')">
              <h1 class="my-2">Current Tenure</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'updated_experience_company' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('loyalty')">
              <h1 class="my-2">Company tenure</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_companies')">
              <h1 class="my-2">Companies</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('salary')">
              <h1 class="my-2">Salary</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('rate')">
              <h1 class="my-2">Rate</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'rate' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('sortingAvailability')">
              <h1 class="my-2">Availability</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'sortingAvailability' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('phone')">
              <h1 class="my-2">Phone</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'phone' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'phone' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('email')">
              <h1 class="my-2">Email</h1>
              <div>
                <svg v-if="this.sort && this.sort.name == 'email' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="this.sort && this.sort.name == 'email' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
              </div>
            </div>

            <hr />

            <div class="text-center mt-3 select-none">
              <span class="text-olive cursor-pointer text-olive" @click="sortProfiles('clear')">Clear filters</span>
            </div>
          </div>

          <div class="flex flex-col mb-5" style="width: 100%">
            <div class="flex flex-col mb-5">
              <label class="relative">
                  <input v-model="this.tempSearch" v-on:keyup.enter="applySearch()"
                      class="w-full bg-white placeholder:font-italitc border border-gray-300 drop-shadow-md rounded-md py-2 pl-3 pr-10 focus:outline-none"
                      placeholder="Search for profiles" type="text" />

                  <span v-if="this.search != ''" class="absolute inset-y-0 right-0 flex items-center pr-3" @click="clearSearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
              </label>
            </div>

            <div class="flex flex-grow flex-col">
              <div v-if="!hasFinishedLoadingMembers" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
                Loading talent pool members...
              </div>
              <div v-if="hasFinishedLoadingMembers && this.poolMembers.length == 0 && this.search == ''" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
                This pool has no members added
              </div>
              <div v-if="hasFinishedLoadingMembers && this.poolMembers.length == 0 && this.search != ''" class="flex bg-white text-gray-200 rounded-xl text-3xl p-10 text-center select-none">
                <span>No profiles are matching your search criteria.</span>
              </div>
              <AddProfilesModal ref="addProfilesModal"  @refreshPool="getPoolMembers"></AddProfilesModal>
              <BigCard v-for="profile in poolMembers" :profile="profile" :key="profile.hash" @remove-profile="removeProfileFromPool" />
            </div>

            <nav class="text-center my-5">
              <ul class="inline-flex -space-x-px">
                <li>
                  <button class="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="prevPage()">Previous</button>
                </li>
                <li>
                  <div class="py-2 px-3 ml-0 select-none">
                    Showing page {{ this.page }} of {{ this.maxPages }} ({{this.totalProfiles }} total profiles)
                  </div>
                </li>
                <li>
                  <button class="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" @click="nextPage()">Next</button>
                </li>
              </ul>
            </nav>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL

import _ from 'lodash'
import BigCard from '@/components/BigCard'
import SmallCard from '@/components/SmallCard'
import AddProfilesModal from '@/components/modals/AddProfilesModal'
import TalentPoolStats from '../components/TalentPoolStats.vue'
import TalentBenchModal from '../components/modals/TalentBenchModal.vue'

export default {
  name: 'TalentPool',
  components: {
    BigCard,
    SmallCard,
    AddProfilesModal,
    TalentPoolStats,
    TalentBenchModal
  },
  data() {
    return {
      talentpool: {
        name: '',
        location: '',
        description: '',
        tags: [],

      },
      talentPoolEditForm: {
        name: '',
        location: '',
        description: '',
        tags: [],
      },
      poolMembers: [],
      isEditing: false,
      error: null,
      errorMessage: null,
      hasFinishedLoadingMembers: false,
      tempSearch: '',
      search: '',
      page: 1,
      maxPages: 1,
      totalProfiles: 0,
      sort: null
    };
  },
  methods: {
    clearSearch() {
      this.tempSearch = ''
      this.search = ''
      // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
      this.page = 1;
      this.getPagedProfiles();
    },
    applySearch() {
      this.search = this.tempSearch
      // Reset page if we are searching for a term as we might end up without results or less results than the page we are currently on
      this.page = 1;
      this.getPagedPoolMembers();
    },
    nextPage() {
      if (this.page < this.maxPages) {
        this.page = (this.page + 1);
        this.getPagedPoolMembers();
        // Scroll page back up
        window.scrollTo(0, 0)
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page = (this.page - 1);
        this.getPagedPoolMembers();
        // Scroll page back up
        window.scrollTo(0, 0)
      }
    },
    async getIndividualPool() {
      try {
        // let response = await fetch(apiEndpoint + "/talentpool.php?getIndividual&tph=" + this.$route.params.poolHash);
        let response = await fetch(apiEndpoint + "/talentpool.php?getIndividual&tph=" + this.$route.params.poolHash, {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.talentpool = await response.json();
        this.$emit('setChildBreadcrumb', this.talentpool.name);
      } catch (error) {
        this.$router.push('Logout')
      }
    },
    async getPagedPoolMembers() {
      let sortField = (this.sort == null) ? '' : this.sort.name
      let sortOrder = (this.sort == null) ? '' : this.sort.order
      let searchTerm = this.search

      let response = await fetch(apiEndpoint + "/talentpool.php?getPagedMembers&tph=" + this.$route.params.poolHash + '&page=' + this.page + '&sortField=' + sortField + '&sortOrder=' + sortOrder + '&search=' + searchTerm, {
        headers: {
          'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
        },
      })

      let res = await response.json();
      // Update profiles
      this.poolMembers = res.profileData
      // Update max pages
      this.maxPages = res.totalPages
      // Save total profiles
      this.totalProfiles = res.totalProfiles
      // Finished loading
      this.hasFinishedLoadingMembers = true;
    },
    async getPoolMembers() {
      // let response = await fetch(apiEndpoint + "/talentpool.php?getMembers&tph=" + this.$route.params.poolHash);
        let response = await fetch(apiEndpoint + "/talentpool.php?getMembers&tph=" + this.$route.params.poolHash, {
          headers: {
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
        })
        this.poolMembers = await response.json();
        this.hasFinishedLoadingMembers = true
    },
    sortProfiles (field) {
      if (field == 'clear') {
        this.sort = null;
        this.getPagedPoolMembers()
        return
      }

      // If current sort is null, we sort by this field in a descending order
      if (this.sort == null) {
        // Set the new sort settings
        this.sort = {
          name: field,
          order: 'desc'
        }
      } else {
        // If the current sorted field is not the same sort by the new field in a descending order
        if (this.sort.name != field) {
          // Set the new sort settings
          this.sort = {
            name: field,
            order: 'desc'
          }
        } else {
          // Otherwise switch the type and order by the new settings
          let newOrder = this.sort.order == 'asc' ? 'desc' : 'asc'

          // Set the new sort settings
          this.sort = {
            name: field,
            order: newOrder
          }
        }

        this.getPagedPoolMembers()
      }
    },
    removeTag(item) {
      let index = this.talentPoolEditForm.tags.indexOf(item)
      if (index !== -1) {
        this.talentPoolEditForm.tags.splice(index, 1);
      }
    },
    addTag() {
      if (!this.talentPoolEditForm.tags.includes(this.tag)) {
        this.talentPoolEditForm.tags.push(this.tag)
      }

      this.tag = ''
    },
    switchEdit() {
      this.talentPoolEditForm = {...this.talentpool}
      this.isEditing = true
    },
    async exportBench() {
      this.$refs.talentBenchModal.openModal(this.talentpool.talent_pool_hash, this.poolMembers);
    },
    async exportPool() {
      const tph = this.$route.params.poolHash
      await fetch(apiEndpoint + '/talentpool.php?exportPool&tph=' + tph, {
        headers: {
          'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          }
      }).then(function(response) {
        // for each response header, log an array with header name as key
        const contentType = response.headers.get('content-type')
        let extension = 'xlsx'

        const outputFileName = tph + '.' + extension
        response.blob().then(function(myBlob) {
            const url = window.URL.createObjectURL(myBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFileName);
            document.body.appendChild(link);
            link.click();
          })
        });
    },
    cancelEditPool() {
      this.isEditing = false
      this.resetError()
    },
    openAddModal() {
      this.$refs.addProfilesModal.openModal(this.talentpool);
    },
    removeProfileFromPool(profileHash) {
      // Remove from array
      _.remove(this.poolMembers, function(n) {
        return n.profile_hash == profileHash
      });

      // Remove from database
      let postData = {
        tph: this.talentpool.talent_pool_hash,
        ph: profileHash
      }

      try {
        fetch(apiEndpoint + "/talentpool.php?removeProfileFromPool", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken'),
          },
          body: JSON.stringify(postData),
        })
        .then(response => response.json())
        .then(data => {
          //
        })
      } catch (error) {
        // Show error
      }
    },
    updatePool() {
      // this.resetError()
      try {
        fetch(apiEndpoint + "/talentpool.php?updatePool&tph=" + this.$route.params.poolHash, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  localStorage.getItem('screenedToken')
          },
          body: JSON.stringify(this.talentPoolEditForm),
          })
          .then(response => response.json())
          .then(data => {
            if (data.status == 'SUCCESS') {
              this.getIndividualPool();

              this.resetError()
              // Hide creation form
              this.isEditing = false;
             } else {
               if (data.status != 'ERROR') {
                 this.errorMessage = data.status
               }
               this.error = true;
             }
          })
        } catch (error) {
          console.log(error);
          this.error = true;
          this.saveButtonText = 'Save changes'
      }
    },
    resetError() {
      this.error = false
      this.errorMessage = false
    },
  },

  created() {
    this.getIndividualPool();
    this.getPagedPoolMembers();
  },
}
</script>
