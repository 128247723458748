<template class="">
    <div class="pt-7 flex justify-center">
      <div class="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 flex flex-col w-1/2">
        <div class="mb-4">
          <h3 class="font-bold">Sign Up</h3>
        </div>

        <div v-if="this.error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span class="block sm:inline">{{ this.errorMessage }}</span>
        </div>
        

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-fname">
              First Name
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-fname" type="text" v-model="this.first_name">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-lname">
              Last Name
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-lname" type="text" v-model="this.last_name">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
              Email
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" v-model="this.email">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
              Phone
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone" type="text" v-model="this.phone">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
              Password
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="password" v-model="this.password">
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-phone">
              Confirm password
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-phone" type="password" v-model="this.passwordConfirm">
          </div>
        </div>
        <!--
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-visa">
              Visa status
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-visa" type="text" v-model="this.visa">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-availability">
              Availability
            </label>
            <div class="relative">
              
              <select @change="availabilityChange($event)" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-availability" v-model="this.selectedAvailability">
                <option v-for="availability in availabilityOptions" :value="availability.value">{{ availability.label }}</option>
              </select>

              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
            
            <Popover v-if="calendarOpen" class="relative">
              <PopoverPanel class="absolute z-10">
                <Datepicker class="my-3" v-model="date" inline autoApply @update:modelValue="updateCalendarDate" hideOffsetDates :enableTimePicker="false" ref="datepicker"></Datepicker>
              </PopoverPanel>
            </Popover>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-rate">
              Rate
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-rate" type="text" v-model="this.rate">
          </div>
        </div>
        
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-industry">
              Industry
            </label>
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-industry" v-model="this.industry">
              <option>Mining</option>
            </select>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-role">
              Role
            </label>
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-role" v-model="this.role">
              <option>Field Assistant</option>
              <option>Geology</option>
            </select>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-workstate">
              Will work outside home state
            </label>
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-workstate" v-model="this.workstate">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-workoverseas">
              Will work overseas
            </label>
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-workoverseas" v-model="this.workoverseas">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-contract_type">
              Contract Type
            </label>
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-contract_type" v-model="this.contract_type">
              <option>PAYG</option>
              <option>PTY LTD</option>
            </select>
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-roster">
              Roster
            </label>
            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-roster" type="text" v-model="this.roster">
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full px-3">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-ideal_role">
              Ideal role / opportunity
            </label>
            <textarea class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="" id="grid-ideal_role" cols="30" rows="3" v-model="this.ideal_role"></textarea>
          </div>
        </div>-->

        <div class="flex items-end justify-between">
          <router-link to="/marketplace/contractor/login">
            <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button">
              Return to login
            </button>
          </router-link>

          <button class="bg-sky-500 hover:bg-sky-400 text-white py-2 px-4 rounded" type="button" @click="submitSignup">
            Submit
          </button>
        </div>
    </div>
  </div>
</template>

<script>
let apiEndpoint = process.env.VUE_APP_API_BASEURL
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'Signup',
  components: {
    Datepicker
  },
  data() {
    return {
      date: new Date(),
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      visa: "",
      availability: 0,
      rate: "",
      industry: "Mining",
      role: "Field Assistant",
      workstate: "Y",
      workoverseas: "Y",
      contract_type: "PAYG",
      roster: "",
      ideal_role: "",
      error: false,
      errorMessage: "",
      calendarOpen: false,
      availabilityOptions: [
        {
          "value": 0,
          "label": "Select availability"
        },
        {
          "value": 0.2,
          "label": "1 week"
        },
        {
          "value": 0.5,
          "label": "2 weeks"
        },
        {
          "value": 1,
          "label": "1 month"
        },
        {
          "value": 999,
          "label": "Custom date"
        }
      ],
      selectedAvailability: 0,
    };
  },
  methods: {
    async submitSignup() {
      // Scroll to top in case of error message
      window.scrollTo(0, 0)
      this.error = false

      // let mandatoryFields = ['first_name', 'last_name', 'email', 'phone', 'password', 'passwordConfirm', 'visa', 'availability', 'rate', 'industry', 'role', 'workstate', 'workoverseas', 'contract_type', 'roster', 'ideal_role']
      let mandatoryFields = ['first_name', 'last_name', 'email', 'phone', 'password', 'passwordConfirm']
      
      // Loop over mandatory fields and check if they are empty
      mandatoryFields.forEach(field => {
        if (this[field] === "") {
          this.error = true
          this.errorMessage = 'Please fill all the fields'
        }
      })

      // Check if passwords match
      if (this.password !== this.passwordConfirm) {
        this.error = true
        this.errorMessage = 'Passwords do not match'
      }

      if (!this.error) {
        // let postObject = {
        //   first_name: this.first_name,
        //   last_name: this.last_name,
        //   email: this.email,
        //   phone: this.phone,
        //   password: this.password,
        //   visa: this.visa,
        //   availability: this.selectedAvailability,
        //   rate: this.rate,
        //   industry: this.industry,
        //   role: this.role,
        //   workstate: this.workstate,
        //   workoverseas: this.workoverseas,
        //   contract_type: this.contract_type,
        //   roster: this.roster,
        //   ideal_role: this.ideal_role
        // }

        let postObject = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          password: this.password
        }

        try {
          await fetch(apiEndpoint + "/marketplace.php?contractorSignup", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postObject),
          })
          .then(response => response.json())
          .then(data => {
            if (data.success === false) {
              this.error = true
              this.errorMessage = data.message
            } else {
              // Write token to localStorage
              localStorage.setItem('contractorToken', data.token);

              // Redirect to profiles
              this.$router.push('/contractor/profile') 
            }
          })
        } catch (error) {
          // console.log('something went realllly worng')
          // this.saveButtonText = 'Save changes'
        }
      }
    },
    // Function to check if form fields are empty
    checkEmptyFields() {
      // let mandatoryFields = ['first_name', 'last_name', 'email', 'phone', 'visa', 'availability', 'rate', 'industry', 'role', 'workstate', 'workoverseas', 'contract_type', 'roster', 'ideal_role']
      let mandatoryFields = ['first_name', 'last_name', 'email', 'phone', 'password', 'passwordConfirm']
      // Loop over mandatory fields and check if they are empty
      mandatoryFields.forEach(field => {
        if (this[field] === "") {
          return false;
        }
      })

      return true;
    },
    availabilityChange(event) {
      if (event.target.value == 999) {
        this.calendarOpen = true
      } else {
        this.calendarOpen = false
      }
    },
    updateCalendarDate(newDate) {
      let newSelectedDate = (newDate.getTime() / 1000)
      this.calendarOpen = false

      // If we already have 5 dates, remove the latest one
      if (this.availabilityOptions.length >= 6) {
        this.availabilityOptions.pop();
      }

      // Add the new date to the dropdown
      this.availabilityOptions.push({
        "value": newSelectedDate,
        "label": "From " + newDate.toLocaleDateString()
      })

      this.selectedAvailability = newSelectedDate
    },
    logoutUser() {  
        // Set state
        this.$store.commit('setLoggedIn', false)
        // Remove localstorage
        localStorage.removeItem('contractorToken');

        // Redirect to login
        this.$router.push('/contractor/login') 
    },
  },
  created() {
    // this.logoutUser();
  },
}
</script>
