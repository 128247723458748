import { createStore, storeKey } from 'vuex'

// Create a new store instance.
export default createStore({
    state () {
      return {
        count: 0,
        isLoggedIn: false,
        isContractorLoggedIn: false,
        isCompanyLoggedIn: false,
      }
    },
    mutations: {
      increment (state) {
        state.count++
      },
      setLoggedIn(state, value) {
        state.isLoggedIn = value
      },
      setContractorLoggedIn(state, value) {
        state.isContractorLoggedIn = value
      },
      setCompanyLoggedIn(state, value) {
        state.isCompanyLoggedIn = value;
      }
    }
  });