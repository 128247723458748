<template>
    <div v-if="show" class="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div class="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
        <!-- Close button -->
        <button @click="closeModal" class="absolute top-4 right-4"> <!-- Increased spacing -->
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
  
        <!-- Instructions -->
        <div class="mb-4 text-center">
          <p class="text-gray-700">Move the image inside the rectangle and use the zoom controls to adjust.</p>
          <p class="text-gray-500 text-sm">Only the area inside the rectangle will be saved.</p>
        </div>
  
        <!-- Image Cropper -->
        <div class="relative flex items-center justify-center mt-6" style="height: 300px;"> <!-- Added margin-top to the image -->
          <img ref="image" :src="image" class="w-full" alt="Uploaded Image" />
        </div>
  
        <!-- Controls -->
        <div class="mt-4 flex justify-between">
          <button @click="zoomIn" class="bg-gray-500 text-white px-4 py-2 rounded-md">Zoom In</button>
          <button @click="zoomOut" class="bg-gray-500 text-white px-4 py-2 rounded-md">Zoom Out</button>
        </div>
  
        <!-- Save Button -->
        <div class="mt-6 text-right">
          <button @click="save" class="bg-blue-500 text-white px-4 py-2 rounded-md">Save</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';
  
  export default {
    props: {
      image: String,    // URL of the image to be shown in the modal
      show: Boolean     // Controls whether the modal is shown or hidden
    },
    data() {
      return {
        cropper: null,  // Cropper instance
      };
    },
    mounted() {
      if (this.show) {
        this.initCropper();  // Initialize Cropper when modal is opened
      }
    },
    watch: {
      show(newVal) {
        if (newVal) {
          this.initCropper();  // Initialize Cropper when modal is shown
        } else {
          if (this.cropper) {
            this.cropper.destroy();  // Clean up Cropper when modal is hidden
          }
        }
      }
    },
    methods: {
      initCropper() {
        const imageElement = this.$refs.image;
        this.cropper = new Cropper(imageElement, {
          aspectRatio: 1,  // Keep it a square to simulate a circle
          viewMode: 3,  // Restrict the crop box to the boundaries of the image
          background: false,  // Remove the default Cropper.js background,
          modal: true,
          guides: false,
          center: false,
          highlight: true,
          autoCropArea: 0.6,
          cropBoxResizable: true,
          toggleDragModeOnDblclick: false,
          dragMode: 'move',
          zoomOnWheel: false,  // You can add custom zoom control
        });
      },
      zoomIn() {
        this.cropper.zoom(0.1);  // Zoom in by 10%
      },
      zoomOut() {
        this.cropper.zoom(-0.1);  // Zoom out by 10%
      },
      save() {
        const croppedImage = this.cropper.getCroppedCanvas().toDataURL();  // Get cropped image as base64
        this.$emit('save', croppedImage);  // Emit the cropped image to the parent
        // console.log(croppedImage)
        this.closeModal();
      },
      closeModal() {
        this.$emit('close');  // Emit a custom event to close the modal
      },
    },
  };
  </script>
  
  <style scoped>
  /* Modal backdrop */
  .bg-smoke-light {
    background: rgba(0, 0, 0, 0.4);
  }
  
  /* Increased margin for the close button */
  button.absolute {
    top: 16px;  /* Increased spacing from top */
    right: 16px; /* Increased spacing from right */
  }
  </style>
  