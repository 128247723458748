<!-- Thank You Component (Ensure it has the same container width as other steps) -->
<template>
  <div class="text-center">
      <!-- <h2 class="text-3xl font-bold text-gray-800 mb-4">Thank You for Registering!</h2> -->
      <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-800">
        Thank You for Signing Up!
      </div>
      <!-- Message -->
      <p class="text-gray-600 mb-6">
        Your registration is complete, and we are currently processing your uploaded resume. The resume information will be visible in your profile within the next minute.
      </p>
      
      <!-- Instructions -->
      <p class="text-gray-600 mb-8">
        You can now proceed to the login page to access your newly created account.
      </p>

      <!-- Login Button -->
      <router-link 
        to="/contractor/login" 
        class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-md inline-block transition duration-150 ease-in-out"
      >
        Go to Login
      </router-link>
  </div>
</template>

<script>
export default {
  name: 'ThankYou'
};
</script>

<style scoped>
/* You can reuse existing styles or add specific ones */
</style>
